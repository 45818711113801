import React, { useState, useContext } from "react";
import { NovaContext } from "../../components/context/index";
// import { v4 as uuidv4 } from "uuid";

import { useNavigate } from "react-router-dom";
// Internal import
import { calculateOpenArrear } from "../../components/Helper/LoanHelpers";

// Material UI import
import Box from "@mui/system/Box";
import {
  CustomDataGrid,
  CustomGridToolbar,
} from "../../components/Helper/MUIStyledComponents";

// Date-fns imports
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  getCustomerStatusIcon,
  getValueFromEnum,
} from "../../components/Helper/GlobalHelpers";
import NewEditCustomer from "../../components/popups/NewEditCustomer";
import { CustomerStatus } from "../../components/Helper/Enums";
import { prepareEmployeeListAllColumns } from "../../components/Helper/DataGrid/ColumnDefinitions/Columns_Employee";

const EmployeeListAll = (props) => {
  const { employees, setLockStatus } = props;
  const { currentRole } = useContext(NovaContext);
  //   const hasRegionalManagerAccess = checkAccess(currentRole, "Gerente Regional");
  //   const hasAdminAccess = checkAccess(currentRole, "Administrador");
  let navigate = useNavigate();

  // if (employees) {
  //   console.log(employees);
  //   employees.map((item) => {
  //     item.fullName = item.employee_name + " " + item.employee_lastname;
  //   });
  // }

  const [filterModel, setFilterModel] = React.useState({
    // items: [{ columnField: "state", operatorValue: "contains", value: "blo" }],
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const boxHeight = "85vh";

  const handleViewEmployee = (employeeId) => {
    const path = `/Employees/${employeeId}`;
    navigate(path, { replace: true });
  };

  const prepareRows = (dataArray) => {
    return dataArray && dataArray[0]
      ? dataArray?.map((item) => {
          return {
            ...item,
          };
        })
      : [];
  };

  return (
    <Box height={boxHeight} width="100%">
      <CustomDataGrid
        columns={prepareEmployeeListAllColumns(employees,currentRole, handleViewEmployee)}
        rows={prepareRows(employees)}
        getRowId={(row) => row.employee_id}
        slots={{
          toolbar: CustomGridToolbar,
        }}
        onFilterModelChange={(newModel) => setFilterModel(newModel)}
        filterModel={filterModel}
        initialState={{
          filter: {
            filterModel: filterModel,
          },
        }}
      />
    </Box>
  );
};

export default EmployeeListAll;

import React, { useContext, useEffect, useState } from "react";
import { NovaContext } from "../../components/context";
import { useParams } from "react-router-dom";

import Loading from "../../components/Actions/Loading";

// Material UI import
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import profile from "../../images/profile-default.png";

// Material UI Date Picker
// import CustomerHeader from "./CustomerHeader";
import { ItemBox } from "../../components/Helper/MUIStyledComponents";

// internal
// import { CustomTabPanel } from "../../components/Helper/GlobalHelpers";
import EmployeeHeader from "./EmployeeHeader";
import EmployeeContent from "./EmployeeContentV2";
import Media_New from "../Modal/Media_New";
import { uploadDestination } from "../../components/Helper/MediaHelpers";
import { getUserRolesOptions } from "../../components/Helper/GlobalHelpers";

const Index = () => {
  const { actions, currentRole, filial, authenticatedUser } = useContext(
    NovaContext
  );

  const params = useParams();
  const employeeId = params.id;
  const errors = [];
  const [employee, setEmployee] = useState("");
  const [loans, setLoans] = useState([]);

  // page-related state
  const [loading, setLoading] = useState(true);
  const [lockStatus, setLockStatus] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [dialogLoan, setDialogLoan] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [audittrailList, setAudittrailList] = useState([]);
  const [EmployeeRoles, setEmployeeRoles] = useState([]);
  const [filials, setFilials] = useState([]);
  const [selectedLoan, setSelectedLoan] = useState(null);
  const [paymentsList, setPaymentsList] = useState([]);
  const [mediaItems, setMediaItems] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // get employee
        const employeeResp = await actions.getEmployee(employeeId);
        // console.log("employee:", employeeResp);
        setEmployee(employeeResp);

        // get loans issued by employee
        const loansResp = await actions.getLoansIssuedByEmployee(employeeId);
        // console.log("Loans:", loansResp);
        setLoans(loansResp);

        // get audit items
        const auditResp = await actions.getEmployeeAudit(employeeId);
        setAudittrailList(auditResp);

        // get all filials to get the names
        const filialListOwnAccess = await actions.getFilials();
        setFilials(filialListOwnAccess);

        const userRoles = await actions.getUserRoles();
        // console.log("userRoleList", userRoles);
        const userRoleOptions = getUserRolesOptions(userRoles,currentRole);
        // console.log("userRoleListOptions", userRoleOptions);
        setUserRoleList(userRoleOptions);

        // get all the filials where the employee has access
        const employeesResp = await actions.getAllEmployees();
        const filteredEmployeeRoles = employeesResp.filter(
          (employeeRole) => employeeRole.employee_id === employeeId
        );
        console.log(
          "Filtered:",
          filteredEmployeeRoles,
          "OwnAccess",
          filialListOwnAccess
        );

        const EmployeeRolesToDisplay = filteredEmployeeRoles
          .map((employee) => {
            const filial = filialListOwnAccess.find(
              (filialObj) => filialObj.filial_id === employee.worksin_filialid
            );

            return filial
              ? {
                  ...employee,
                  filial_name: filial.filial_name, // Include authorized filial
                }
              : null; // Exclude unauthorized filial by returning null
          })
          .filter(Boolean); // Remove null entries

        setEmployeeRoles(EmployeeRolesToDisplay);

        setLoading(false);
      } catch (err) {
        errors.push(err);
      }
    };
    // get media added by employee

    fetchData();
  }, []);

  const updateEmployeeRole = (worksin_id, newRoleName) => {
    console.log("updateEmployeeRole", worksin_id, newRoleName);
    setEmployeeRoles((prevRoles) =>
      prevRoles.map((role) =>
        role.worksin_id === worksin_id
          ? { ...role, role_name: newRoleName }
          : role
      )
    );
  };

  const removeEmployeeRole = (worksin_id) => {
    console.log("removeEmployeeRole", worksin_id);
    setEmployeeRoles((prevRoles) =>
      prevRoles.filter((role) => role.worksin_id !== worksin_id)
    );
  };

  // toggle for payments list
  const toggleDrawer = (loan) => {
    const loanId = loan.loan_id;
    setPaymentsList(getPaymentsFromLoan(loanId));
    setSelectedLoan(loan);
    setDrawer(true);
  };

  // toggle for payment Dialog
  const togglePaymentDialog = (loan) => {
    setSelectedLoan(loan);
    setDialogPayment(true);
  };

  // triggered when the information icon per loan is clicked
  const getPaymentsFromLoan = async (loanId) => {
    await actions
      .getPaymentsFromLoan(loanId)
      .then((resp) => {
        setPaymentsList(resp);
      })
      .catch((err) => console.log(err));
  };

  const updateMedia = (newMedia, command) => {
    if (command === "ADD") {
      setMediaItems((prevState) => {
        return [...prevState, newMedia];
      });
    } else {
      setMediaItems((prevState) => {
        return prevState.filter((item) => item.media_id !== newMedia.media_id);
      });
    }
  };

  const handleUploadMedia = (newMedia) => {
    updateMedia(newMedia, "ADD");
  };

  return (
    <Grid container sx={{ padding: { xs: "10px", md: " 25px" } }}>
      <div
        className={`over-page-loading ${
          lockStatus || loading ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando..."}
            classNames={`over-page-loading ${
              lockStatus || loading ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>

      {lockStatus || loading ? (
        <div>Cargando...</div>
      ) : (
        <Grid container size={12} spacing={2}>
          <Grid size={12}>
            <EmployeeHeader employee={employee} />
          </Grid>
          <Grid size={{ xs: 12, md: 4 }}>
            <Grid size={{ xs: 12 }}>
              <ItemBox>
                <Box
                  padding={1}
                  position={"relative"}
                  sx={{
                    height: 350,
                    orderRadius: "50%",
                    overflow: "hidden",
                  }}
                >
                  <Box
                    component="img"
                    // src={profilePic ? profilePic.location : defaultProfile}
                    src={profile}
                    sx={{
                      height: "100%",
                      // width: "100%",
                      objectFit: "cover",
                      borderRadius: "25%",
                      // borderRadius: "50%",
                    }}
                  />
                  <Box position={"absolute"} right={0} bottom={0}>
                    New Edit
                  </Box>
                </Box>
              </ItemBox>
            </Grid>
            <Grid size={{ xs: 12 }} sx={{ height: { md: "365px" } }}>
              Info Tabs
            </Grid>
            {/* </Grid> */}
          </Grid>

          <Grid size={{ xs: 12, md: 8 }}>
            <Box position={"relative"}>
              <ItemBox padding={1}>
                <EmployeeContent
                  employee={employee}
                  employeeLoans={loans}
                  loansLoaded={true}
                  EmployeeRoles={EmployeeRoles}
                  employeeMedia={mediaItems}
                  userRoleList={userRoleList}
                  updateEmployeeRole={updateEmployeeRole}
                  removeEmployeeRole={removeEmployeeRole}
                  // viewLoanDetails={toggleDrawer}
                  // togglePaymentDialog={togglePaymentDialog}
                  // customerAudit={audittrailList}
                  // auditLoaded={true}
                  // mediaLoaded={false}
                  // updateMedia={updateMedia}
                />

                <Box
                  position={"absolute"}
                  top={{ xs: 12, md: 20 }}
                  right={12}
                  display={"flex"}
                  alignItems={"center"}
                >
                  <Media_New
                    objectUuid={authenticatedUser.employee_id}
                    afterAddFunction={handleUploadMedia}
                    destination={uploadDestination.EMPLOYEE}
                  />
                </Box>
              </ItemBox>
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Index;

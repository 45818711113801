import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// internal misc
import {
  CustomDataGrid,
  CustomFooter,
  CustomGridToolbar,
} from "../../../components/Helper/MUIStyledComponents";
import { alerts, displayAlert } from "../../../components/Helper/AlertToastify";
import { usePaymentsContext } from "../../../components/context/PaymentsContext";
import { deletePayment } from "../../../utils/api/api_payments";
import { PaymentActions } from "../../../reducers/PaymentReducer";

// components
import DateRange from "../../../components/common/DateRange/DateRange";
import { DateRangeActions } from "../../../components/common/DateRange/DateRangeReducer";
import Confirmation from "../../../components/common/Confirmation";
import CustomLoadingOverlay from "../../../components/Helper/DataGrid/Overlays/CustomLoadingOverlay";
import { renderNoRowDataOverlay } from "../../../components/Helper/DataGrid/Overlays/RenderNoRowDataOverlay";

// helper
import { preparePaymentColumns } from "../../../components/Helper/DataGrid/ColumnDefinitions/Columns_Payments";

// mui
import Box from "@mui/material/Box";

const PaymentsList = ({ columnVisibilityModel }) => {
  const [selectedPayment, setSelectedPayment] = useState();
  const {
    loading,
    paymentList,
    dateRange,
    dispatchDateRange,
    currentRole,
    dispatchPaymentList,
  } = usePaymentsContext();
  const navigate = useNavigate();

  
  // modal states
  const [confirmation, setConfirmation] = useState(false);

  // what does filter model do?
  const [filterModel, setFilterModel] = React.useState({
    items: [],
    quickFilterExcludeHiddenColumns: true,
    quickFilterValues: [""],
  });

  const changeDateRange = (updatedDates) => {
    dispatchDateRange({
      type: DateRangeActions.SET_DATE_RANGE_FILTER,
      payload: updatedDates,
    });
  };

  const handleRemovePayment = async () => {
    await deletePayment(selectedPayment)
      .then((resp) => {
        if (resp.status === 200) {
          displayAlert(alerts.SUCCESS, "Pago eliminado");
          setConfirmation(false);
          setSelectedPayment();
          dispatchPaymentList({
            type: PaymentActions.REMOVE_PAYMENT,
            payload: selectedPayment,
          });
        } else {
          displayAlert(alerts.ERROR, `${resp.text}`);
        }
      })
      .catch((err) => displayAlert(alerts.ERROR, `${err}`));
  };

  const visibilityModel = columnVisibilityModel(currentRole);

  // Calculate the total for the "amount" column
  const totalAmount =
    paymentList && paymentList.length > 0
      ? paymentList.reduce((acc, row) => acc + (row.payment_total || 0), 0)
      : 0;
  // console.log("total:", totalAmount);

  return (
    <div>
      <Confirmation
        open={confirmation}
        setOpen={setConfirmation}
        textContent={"Estás seguro de que quieres ELIMINAR este pago?"}
        confirmationMethod={handleRemovePayment}
      />
      {loading ? (
        "Cargando..."
      ) : (
        <Box height="85vh" width="100%">
          <CustomDataGrid
            columns={preparePaymentColumns(
              setConfirmation,
              setSelectedPayment,
              navigate,
              currentRole
            )}
            rows={paymentList}
            getRowId={(row) => row.payment_id}
            slots={{
              toolbar: CustomGridToolbar,
              footer: CustomFooter,
              loadingOverlay: CustomLoadingOverlay,
              noRowsOverlay: () =>
                renderNoRowDataOverlay("No hay pagos en este periodo"),
            }}
            slotProps={{
              toolbar: {
                component1: (
                  <DateRange
                    dateObject={dateRange}
                    changeMethod={changeDateRange}
                  />
                ),
              },
              footer: { totalAmount },
            }}
            onFilterModelChange={(newModel) => setFilterModel(newModel)}
            filterModel={filterModel}
            initialState={{
              columns: {
                columnVisibilityModel: visibilityModel,
              },
              filter: {
                filterModel: filterModel,
              },
            }}
          />
        </Box>
      )}
    </div>
  );
};

export default PaymentsList;

import React, { useEffect } from "react";
import { getLoans } from "../../utils/api/api_loan";
import { useLoansContext } from "../context/LoansContext";

// reducers
import { LoanActions } from "../../reducers/LoansReducer";
import { LoadingActions } from "../../reducers/LoadingReducer";

import { alerts, displayAlert } from "../Helper/AlertToastify";

const LoansController = ({
  statusFilter = "OPEN",
  sortingFilter = "loan_dateissue DESC",
  customerIdFilter,
  employeeIdFilter,
  startDateFilter = false,
  endDateFilter = false,
}) => {
  const {
    filial,
    dateRange,
    dispatchLoanList,
    dispatchLoading,
  } = useLoansContext();
  const start = dateRange.start ? dateRange.start.toISOString() : null;
  // const end = dateRange.end ? dateRange.end.toISOString() : null;
  const end = null;

  useEffect(() => {

    // Construct the query parameters
    let queryParams = `filial=${filial}&sorting=${sortingFilter}`;

    if (start && !startDateFilter) {
      queryParams += `&start=${start}`;
    }
    if (end && !endDateFilter) {
      queryParams += `&end=${end}`;
    }
    if (statusFilter && statusFilter !== "none") {
      queryParams += `&status=${statusFilter}`;
    }
    if (customerIdFilter) {
      queryParams += `&customerId=${customerIdFilter}`;
    }
    if (employeeIdFilter) {
      queryParams += `&employeeId=${employeeIdFilter}`;
    }

    getLoans(queryParams)
      .then((resp) => {
        dispatchLoanList({
          type: LoanActions.SET_LOAN,
          payload: resp,
        });
      })
      .catch((err) =>
        displayAlert(
          alerts.ERROR,
          `No se han podido cargar los préstamos: ${err}`
        )
      )
      .finally(() =>
        dispatchLoading({ type: LoadingActions.SET_LOADING, payload: false })
      );
  }, [filial, dateRange, customerIdFilter, employeeIdFilter]);

  return null;
};

export default LoansController;

import { PAYCHECKS as paychecks } from "../config/routes";
import apiClient from "../../lib/api_request/api_request_V3";

export const getPaychecks = async (params) => {
    console.log("params: ", params)
    return await
        apiClient.get(`${paychecks}?${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

export const addPaycheck = async (payCheck) => {
    return await
        apiClient.post(`${paychecks}`, payCheck)
            .then(resp => resp.data)
            .catch(err => err)
}

export const deletePaycheck = async (id) => {
    return await
        apiClient.delete(`${paychecks}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}
import { PAYMENTS as payments } from "../config/routes";
import apiClient from "../../lib/api_request/api_request_V3";

export const getPayments = async (params) => {
    return await
        apiClient.get(`${payments}${params}`)
            .then(resp => resp.data)
            .catch(err => err)
}

export const deletePayment = async (id) => {
    return await
        apiClient.delete(`${payments}/${id}`)
            .then(resp => resp)
            .catch(err => err)
}

// where is post payment?


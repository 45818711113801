import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NovaContext } from "../../components/context";
import { Grid2 as Grid, Button, Typography, Box, Paper } from "@mui/material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  checkMinAccess,
  filterFilialsWithAccess,
  getUserRolesOptions,
} from "../../components/Helper/GlobalHelpers";
import { ItemPaper } from "../../components/Helper/MUIStyledComponents";
import Loading from "../../components/Actions/Loading";
import EmployeeListAll from "./EmployeeListAll";
// import { checkMinAccess } from "../../components/Helper/GlobalHelpers";
// import EmployeeDialog from "./EmployeeForm";
// import EmployeeList from "./EmployeeList_Old";
// import AddIcon from "@mui/icons-material/Add";
// import Controls from "../../components/controls/Controls";
// import EditIcon from "@mui/icons-material/Edit";
// import Popup from "../../components/Popup";
// import EmployeeForm from "./EmployeeForm";

const Index = () => {
  const { actions, filial, filials, currentRole } = useContext(NovaContext);

  // console.log(filials);
  filials.sort((a, b) =>
    a.filial_name > b.filial_name ? 1 : b.filial_name > a.filial_name ? -1 : 0
  );

  const [loading, setLoading] = useState(true);
  const [employees, setEmployees] = useState([]);
  const navigate = useNavigate();
  const hasMinRegionalManagerAccess = checkMinAccess(
    filials,
    "Gerente Regional"
  );
  const hasMinAuditorAccess = checkMinAccess(filials, "Auditor");

  const getEmployeesData = async (filialsList) => {
    var tempEmployeeListComplete = await actions.getAllEmployees();

    // Create a unique list of employees
    var tempEmployeeList = tempEmployeeListComplete
      .filter(
        (obj1, i, arr) =>
          arr.findIndex((obj2) => obj2.employee_id === obj1.employee_id) === i
      )
      .filter((val) => {
        if (currentRole !== "Root" && val.role_name === "Root") {
          return false; // Exclude entries where the current role is not 'Root' but the entry's role is 'Root'
        }
        return true; // Include all other entries
      });

    // Map through the unique employee list to add roles and filial names
    var employeesWithDetails = tempEmployeeList.map((employee) => {
      // Filter the complete list to get all entries for the current employee
      var employeeEntries = tempEmployeeListComplete.filter(
        (entry) => entry.employee_id === employee.employee_id
      );

      // Map through the entries to get the roles and filial names
      var rolesAndFilials = employeeEntries
        .map((entry) => {
          var filial = filialsList.find(
            (filial) => filial.filial_id === entry.worksin_filialid
          );
          return {
            role_name: entry.role_name,
            filial_name: filial ? filial.filial_name : "Unknown",
          };
        })
        .filter((entry) => entry.filial_name !== "Unknown"); // Filter out entries with 'Unknown' filial_name

      // Return the employee object with the added roles and filials
      return {
        ...employee,
        rolesAndFilials: rolesAndFilials,
      };
    });

    // Remove employees who do not have any role in a filial that exists in the filialsList
    var filteredEmployees = employeesWithDetails.filter((employee) =>
      employee.rolesAndFilials.some(
        (roleAndFilial) => roleAndFilial.filial_name !== "Unknown"
      )
    );

    setEmployees(filteredEmployees);
  };

  useEffect(() => {
    const fetchData = async () => {
      //console.log('Filials:',filials);
      console.time("Filials");
      if (hasMinAuditorAccess || hasMinRegionalManagerAccess) {
        setLoading(true);
        const tempFilials = [...filials];
        filterFilialsWithAccess(tempFilials);
        getEmployeesData(tempFilials);
      }
      console.timeEnd("Filials");
    };

    const fetchDataAsync = async () => {
      await fetchData();
      setLoading(false);
    };

    fetchDataAsync();
  }, [actions, currentRole]);

  return (
    <Box sx={{ padding: { xs: "10px", md: "25px" } }}>
      <div
        className={`over-page-loading ${
          loading ? "show-loading" : "hide-loading"
        }`}
      >
        <div className="over-page-loading-content">
          <Loading
            text={"Cargando ..."}
            classNames={`over-page-loading ${
              loading ? "show-loading" : "hide-loading"
            }`}
          />
        </div>
      </div>

      {loading ? (
        <div>Cargando...</div>
      ) : (
        <ItemPaper>
          <Grid container spacing={2}>
            <Grid size={0} md={7}></Grid>
            <Grid size={12} md={5} align="right">
              {/*<NewEditCustomer modalClass="modal" setLockStatus={setLoading} />*/}
              Crear Empleado (tbd)
            </Grid>
            <Grid size={12}>
              <EmployeeListAll
                employees={employees}
                setLockStatus={setLoading}
              />
            </Grid>
          </Grid>
        </ItemPaper>
      )}
    </Box>
  );
};

export default Index;
